<template>
<div class="page-center padded form-wrapper">

    <h1>Contact form</h1>

    <p>Thank you for your interest in improving the website. Please follow these steps to contact us.</p>

    <h3>1. Select the type of contact</h3>

    <div class="flex-container side-padded">
        <select v-model="contact_type" class="w3-input w3-border w3-white">
            <option value="">Select...</option>
            <option value="suggestion">Suggest a new entry to the database</option>
            <option value="problem">Report a problem with the website</option>
            <option value="contact">Contact our staff</option>
        </select>
    </div>

    <!-- Step 2, depending on the contact type-->

    <section v-if="contact_type!=''" class="semi-padded">
    
        <h3>2. Complete the required information</h3>

        <!-- Suggestion -->
        <div class="padded" v-if="contact_type=='suggestion'">

            <div class="w3-card padded">

                <h4>Contact email<mandatory></mandatory></h4>
                <input type="email" class="w3-input w3-border" placeholder="Contact email" v-model="email">
            
                <h4>Country <mandatory></mandatory></h4>
                <country-list></country-list>

                <h4>Title <mandatory></mandatory></h4>
                <input type="text" class="w3-input w3-border" v-model="title">
                
                <h4>Years</h4> 
                <div class="flex-container flex-align-center">
                    <span class="side-padded">From:</span>
                    <input type="number" min="0" class="w3-input w3-border" v-model="fromyear">
                    <span class="side-padded">To:</span>
                    <input type="number" min="0" class="w3-input w3-border" v-model="toyear">
                </div>
                <h4>Link <mandatory></mandatory></h4>
                <input type="url" class="w3-input w3-border" v-model="link">
                <h4>Cost <mandatory></mandatory></h4>
                <select class="w3-input w3-border w3-white" v-model="cost">
                    <option value="Paid">Paid</option>
                    <option value="Free">Free</option>
                </select>
                <h4>Notes <mandatory></mandatory></h4>
                <limit-entry v-model="notes"></limit-entry>
            </div>        
        </div>


        <!-- Problem -->
        <div class="padded" v-if="contact_type=='problem'">
            <div class="w3-card padded">
                
                <h4>Contact email<mandatory></mandatory></h4>
                <input type="email" class="w3-input w3-border" placeholder="Contact email" v-model="email">

                <h4>Description of the problem <mandatory></mandatory></h4>
                <limit-entry v-model="notes" placeholder="Brief description of the issue and expected results"></limit-entry>
            </div>        
        </div>

        <!-- Contact, message -->
        <div class="padded" v-if="contact_type=='contact'">
            <div class="w3-card padded">
                
                <h4>Contact email<mandatory></mandatory></h4>
                <input type="email" class="w3-input w3-border" placeholder="Contact email" v-model="email">

                <h4>Message<mandatory></mandatory></h4>
                <limit-entry v-model="notes" placeholder="Enter your comments here..."></limit-entry>

            </div> 
        
        </div>

        <div class="side-padded w3-right-align">
            <mandatory></mandatory> Mandatory fields
        </div>

        <h3>3. Submit the form</h3>

        <div class="padded">
            <button class="w3-button w3-blue w3-hover-blue" :disabled="!validForm()" @click="submitForm()">Submit</button>
            <button class="w3-button w3-black w3-hover-blue" @click="resetForm()">Cancel</button>
        </div>

    </section>


</div>  
</template>

<script>
import contactService from "@/services/contactService.js";
import CountryList from "@/components/lists/countryList.vue";

export default {
    data(){return {
        contact_type: "",
        title:"",
        notes:"",
        cost: "Free",
        link:"",
        email:""
    }},
    methods:{
        isFormValid(){

        },
        resetForm(){
            let s=this;

            s.contact_type= "";
            s.title="";
            s.notes="";
            s.cost= "Free";
            s.link="";
            s.email="";
        },
        submitForm(){
            let s=this,
                payload={}
            ;
            switch(s.contact_type){
                case "suggestion":
                    payload={
                            email: s.email,
                            country: s.$store.state.search.country, 
                            title: s.title, 
                            fromyear: s.fromyear, 
                            toyear:s.toyear, 
                            link:s.link,
                            cost: s.cost,
                            notes:s.notes
                            }
                    break;
                case "problem":
                case "contact":
                    payload={email: s.email, notes: s.notes};
                    break;
            }

            // Send the email
            s.$notifications.showProgress("Submitting the form. Please wait.");
            contactService
                .requestSendEmail(s.contact_type, payload)
                .then(()=>{
                    s.$notifications.showSuccess("Contact form submitted!");
                },()=>{
                    s.$notifications.showError("The email could not be delivered. <br>Internal server error");
                })
                .finally(()=>{
                    s.$notifications.hideProgress();
                })
        },
        validForm(){
            let s=this;
            switch(s.contact_type){
                case "suggestion":
                    return contactService.validateEmail(s.email) && s.title.length && s.notes.length>10 && s.title.length && s.link.length;
                    
                case "problem":
                case "contact":
                    return contactService.validateEmail(s.email) && s.notes.length>10;
            }
        }
    },
    components:{
        CountryList
    }
}
</script
        Mandatory>

<style scoped>


</style>